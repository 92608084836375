<template>
  <AppHeader />
  <div class="flex col" id="gdpr">
    <h1 class="titlu">Evenimentele noastre</h1>
    <div class="flex">
      <div class="events">
        <div 
          class="event" 
          v-for="event in events" 
          :key="event.id" 
        >
          <a :href="event.link">
            <img :src="event.image" alt="Event image" class="animate__animated" :class="currentEventAnimation">
          </a>
          <p class="animate__animated" :class="currentEventAnimation">{{ event.description }}</p>
        </div>
      </div>
    </div>
    <!-- Loop through the remaining images starting from the second one -->
    <!-- <div class="images">
      <div v-for="(image, index) in event.images.slice(1)" :key="index">
        <img :src="image" :alt="`Image ${index + 2} of ${event.title}`">
      </div>
    </div> -->
    
  </div>
  <AppFooter />
  <AppCookie />
</template>
  
  <script>
    import axios from 'axios';
    
    export default {
  name: 'EvenimentePagina',
  data() {
    return {
      currentIndex: 0,
      currentAnimation: '',
      event: null,
      events: [
          {
            image: require('@/assets/poza1_2.png'), // Adjust the path as necessary
            description: 'Diana si Sebastian',
            link: "/event/1"
          },
          {
            image: require('@/assets/poza2_2.png'), // Adjust the path as necessary
            description: 'Baby Carol',
            link: "/event/2"
          },
          {
            image: require('@/assets/poza3_2.png'), // Adjust the path as necessary
            description: 'Baby Natalia',
            link: "/event/3"
          },
          {
            image: require('@/assets/poza4.png'), // Adjust the path as necessary
            description: 'Elena & Alex',
            link: "/event/4"
          },
        ]
    };
  },
  created() {
    this.fetchEventData();
  },
  methods: {
    fetchEventData() {
      axios.get('/events.json')
        .then(response => {
          const eventId = this.$route.params.id;
          const event = response.data.events.find(e => e.id === eventId);
          if (event) {
            this.event = event;
          }
        })
        .catch(error => {
          console.error("Error fetching events:", error);
        });
    },
    // Method for sliding functionality if needed, but currently image loading logic is not required
    prevSlide() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.event.reviews.length - 1;
      } else {
        this.currentIndex--;
      }
    },
    nextSlide() {
      if (this.currentIndex === this.event.reviews.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    }
  }
};
</script>
    
  
  <style scoped>
      #gdpr{
        background-color: #121212;
        min-height: 100vh;
        width: 100vw;
        box-sizing: border-box;
        color: white;
        padding: 10vw;
        padding-top: 12vh;
      }

      #gdpr .titlu{
        align-self: center;
        width: fit-content;
        font-size:min(17.5vw, 6.5rem);
        text-align: center;
      }

      #gdpr img{
        max-width: 100%;
      }

      

      .images{
        display: none;
      }

      .first-image{
        
      }

      #main{
        align-items: center;
        
        align-self: center;
        width: 85%;
        flex-direction: column;
        margin-top: 6rem;
      }

      #main img{
        width: 100%;
      }

      .events{
        flex-direction: column;
        display: flex;
        row-gap: 2rem;
      }
        @media (min-width: 900px){
          .first-image{
            height: 55vh;
          width: auto;
          object-fit: contain;
          }

          #main{
            flex-direction: row;
            gap: max(5%, 3rem);
          }

          .images{
            display: flex;
            /* flex-direction: column; */
            flex-direction: row;
            width: 70%;
            justify-content: space-around;
            align-items: center;
            gap: 3rem;
            margin-top: 10rem;
            align-self: center;
          }

          .images img{
            width: auto;
            object-fit: contain;
            
            height: 85%;
          }

          .events{
            flex-direction: row;
          }
        }
  </style>