<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

</script>

<style>
 @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Ephesis&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


  *{
    font-family: 'Poppins', sans-serif;
  }

  body{
    margin: 0;
    padding-top: 6vh;
    overflow-x: hidden;
  }


  


  .timeline-container {
    position: relative;
    margin: 0 auto;
    padding: 20px 0;
    width: 100%;
}

.timeline-item {
    display: flex;
    align-items: center;
    margin: 4rem 0;
    position: relative;
}

.timeline-item::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #c69b4e;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.timeline-item .timeline-point {
    width: 20px;
    height: 20px;
    background-color: #c69b4e;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    left: 0%;
    transform: translateX(-50%);
}

.timeline-item.left .timeline-content {
    text-align: left;
    width: 80%;
    order: 1;
    /* padding-right: 20px; */
}

.timeline-item.right{
  flex-direction: row-reverse;
}

.timeline-item.left{
  flex-direction: row-reverse;
}

.timeline-item.right .timeline-content {
    text-align: left;
    width: 80%;
    order: 3;
    /* padding-left: 20px; */
    
}

.timeline-item.left .timeline-point {
    order: 2;
}

.timeline-item.right .timeline-point {
    order: 1;
}

.timeline-item .timeline-content p {
    margin: 0;
}


.timeline-container::before {
  content: "";
  position: absolute;
  width: 2px;
  background-color: #fff; /* White line */
  top: 6.5rem; /* Start the line from the first point */
  bottom: 6.5rem; /* End the line at the last point */
  left: 0%;
  transform: translateX(-50%);
  z-index: 1;
}

  


      .flex{
        display: flex;
      }

      .container{
        display:flex;
        height:95vh;
        width: 100vw;
        max-width: 100vw !important;
        padding-left: 3rem;
        padding-right: 3rem;
        box-sizing: border-box;
        align-items: center;
        padding-top: 2rem;
      }

      h1{
        font-family:'Ephesis', cursive;;
      }

      .col{
        flex-direction: column;
      }

      .margine{
        padding-left: 4rem !important;
        padding-right: 4rem !important;
      }

      #rec{
        background: transparent;
        background-image: url('./assets/Group 224.png');
        background-size: cover;
        background-position: center;
        z-index: 0;
        backdrop-filter: brightness(0%);
        justify-content: center;
      }

      #rec::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%; /* Adjust the height of the shadow effect */
        background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(18, 18, 18,0.8) 100%);
        z-index: 2;
      }

      #rec h1{
        font-family: 'Ephesis', cursive;
        font-size: 5.5rem;
        text-align: center;
        /* margin-top: 60vmin; */
        color: white;
        z-index: 3;
      }
      #rec h1 span{
        color: #A58A5E;
        font-family: 'Ephesis', cursive;
        font-weight: 100;
      }

      #rec .buton_maro{
        width: fit-content;
        padding: 0 3rem;
        box-sizing: content-box;
      }


      .buton_maro{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #A58A5E;
        border: 4px solid #816334;
        border-radius: 0.5rem;
        text-align: center;
        width: 70%;
        align-self: center;
        height: 6.5%;
        text-decoration: none;
        font-size: calc(1.2rem);
        z-index: 3;
      }

      #misiune{
        background-color: #121212;
        color: white;
        
        height: fit-content;
        gap: 3rem;
        padding-bottom: 9rem;
      }

      #misiune img{
        width: 100%;

      }

      .titlu{
        font-family: 'Ephesis', cursive;
        font-size: 4.3rem;
        width: 50%;
        margin-top: 1rem;
        margin-bottom: 5rem;
        font-weight: 200;
        line-height: 4rem;
      }

      .paragraf{
        font-size: 1.1rem;
      }

      #misiune .titlu{
        width: 100%;
      }

      #misiune .buton_maro{
        align-self:flex-start;
        width: 80%;
        height: 2.8rem;
        font-size: 1.2rem;
        font-family: 'Futura PT', sans-serif;
      }

      #pasi{
        background-color: #E2E1DF;
        
        height: fit-content;
        align-items: center;
        padding-top: 8rem;
        padding-bottom: 8rem;
      }

      #pasi .titlu{
        width: 100%;
        text-align: center;
        margin-bottom: 3rem;
      }

      .pas{
        color: #313131;
        display: flex;
        justify-content: space-between;
        width: 90%;
        align-items: center;
        font-size: 1.4rem;
        gap: 2rem;
      }

      .pas p{
        width: 70%;
      }

      .pas img{
        height: 3.5rem;
        width: 3.5rem;
      }

      #planificare{
        background-color: #121212;
        color: white;
        
        height: fit-content;

        padding-top: 8rem;
        padding-bottom: 8rem;
      }

      #planificare .titlu{
        width: 100%;
      }

      #coordonare{
        background-color: #E2E1DF;
        color: #313131;
        padding: 0;
        /*  */
        height: fit-content;

        padding-top: 8rem;
        /* padding-bottom: 8rem; */
      }
      #coordonare .titlu{
        width: 100%;
      }
      #coordonare .container{
        
        height: fit-content;
      }

      #imagDesktop{
        display: none;
      }

      #imagMobil{
        width: 100%;
      }

      #secthContainer{
        padding: 0 min(10%, 15rem);
      }

      #reviews{
        background-color: #121212;
        color: white;
        
        align-items: center;
        height: fit-content;

        padding-top: 8rem;
        padding-bottom: 8rem;
      }

      

      #reviews .titlu{
        width: 100%;
        margin-bottom: 4rem;
      }

      #revimg{
        border-radius: 1rem;
         width: 100%; /*AICI E PROBLEMA, ADAUGA UN ID PENTRU POZA MARE CA SA SE SCALEZE IAR BUTOANELE*/
        height: auto;
      }

      .butoane{
        width: 100%;
        display: flex;
        height: 3rem;
        justify-content: space-around;
        gap: 0.3rem;
      }
      #buton_stanga, #buton_dreapta{
        height: 100%;
        width: 2.5rem;
      }
      .punct{
        background-color: white;
        opacity: 0.4;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        align-self: center;
      }

      .auriu{
        background-color:#A58A5E;
        opacity: 1;
      }

      #evenimente{
        background-color: #121212;
        color: white;
        height: fit-content;
        padding-bottom: 8rem;
        padding-top: 8rem;
      }

      #evenimente .titlu{
          width: 100%;
          margin-bottom: 4rem;
      }

      .event{
        position: relative;
        
      }

      .event img{
        width: 100%;
        filter: brightness(45%);
        margin-bottom: 1rem;
      }

      .event p{
        font-family: 'Ephesis', cursive;
        font-size: 2.5rem;
        position: absolute;
        bottom: 8px;
        left: 16px;
        text-align: center;
      }
      .contact .titlu{
        width: 100%;

      }

      .contact{
        background-color: #E2E1DF;
        height: fit-content;
        padding-bottom: 8rem;
        padding-top: 8rem;
      }

      .contact_form{
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .inpDesktop{
        width: 100%;
      }

      .contact_form input{
        outline: none;
        border: 2px solid rgba(98, 82, 57, 0.45);
        height: 3rem;
        width: 100%;
        border-radius: 1rem;
        box-sizing: border-box;
        padding: 1rem;
        font-size: 1.2rem;
      }

      .contact_form textarea{
        outline: none;
        border: 2px solid rgba(98, 82, 57, 0.45);
        height: 18rem;
        border-radius: 1rem;
        box-sizing: border-box;
        padding: 1rem;
        font-size: 1.2rem;
      }

      .contact_form button{
        width: 80%;
        box-sizing: border-box;
        padding: 0.5rem;
        margin-top: 1rem;
        align-self: flex-start;
      }

      .contact_form label{
        font-size: large;
      }

      .contact_form textarea::placeholder, input::placeholder{
        font-size: 1.2rem;
        opacity: 0.3;
        font-weight: 500;
      }

      .events{
        display: none;
      }

      #buton_dreapta_desktop, #buton_stanga_desktop{
        display: none;
      }

      .sageata{
        display: none;
      }

      .plans, .pp{
        display: none;
      }

      .planMobil{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* gap: 2rem; */
      }

      .planMobil .plans{
        background-color: #816334;
        box-sizing: border-box;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .planMobil .plans, .planMobil .pp{
        display: flex;
      }

      .planMobil .pp{
        width: 80%;
      }

      @media (min-width: 1024px) {
        .titlu{
          
        }

        .timeline-item.right{
          flex-direction: row-reverse;
        }

        .timeline-item.left{
          flex-direction: row;
        }
        
        .timeline-item {
          display: flex;
          align-items: center;
          margin: 2rem 0;
          position: relative;
      }

        .timeline-item .timeline-point {
          width: 20px;
          height: 20px;
          background-color: #c69b4e;
          border-radius: 50%;
          z-index: 1;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
      }


      .timeline-item.right .timeline-content {
        text-align: left;
        width: 45%;
        order: 3;
        padding-left: 20px;
        
    }


    .timeline-item.left .timeline-content {
      text-align: right;
      width: 45%;
      order: 1;
      padding-right: 20px;
  }


  .timeline-container::before {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #fff; /* White line */
    top: 4.5rem; /* Start the line from the first point */
    bottom: 5rem; /* End the line at the last point */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }



        .mobilOnly{
          display: none;
        }

        .planMobil{
          display: none;
        }

        #rec h1{
          font-size: 9rem;
          width: 70%;
          font-weight: 200;
        }

        #rec .buton_maro{
          width: 18rem;
          height: 3rem;
        }

        .buton_maro{
          width: 20%;
        }

        .container{
          padding-left: min(10%, 15rem);
          padding-right: min(10%, 15rem);
          
        }

        #misiune{
          flex-direction: row;
          gap: 20%;
        }

        #misiune .buton_maro{
          width: max(18rem, 35%);
        }

        .half_text{
          display: flex;
          flex-direction: column;
        }
        

        #pasiDesktop{
          display: flex;
          width: 100%;
          gap: 3rem;
          box-sizing: border-box;
          padding: 3rem 0;
        }

        .pas{
          width: 30%;
          font-weight: 500;
        }

        .pas img{
          height: 75%;
          width: auto;
        }

        .gridContainer{
          display: grid;
          width: 100%;
          height: fit-content;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-template-rows: 7rem 5rem 7rem 5rem 7rem;

          grid-template-areas: 
          "p1 p1 p1 . . . . . . ."
          "s . . . . . . p5 p5 p5"
          ". p2 p2 p2 . . s . . ."
          ". s . . . . p4 p4 p4 ."
          ". . p3 p3 p3 s . . . .";
          box-sizing: border-box;
          padding-bottom: 5rem;
        }

        .plans{
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          background-color: #A58A5E;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3rem;
          margin: auto;
        }
      

        .pp{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 5rem;
          margin: auto;
          text-align: center;
        }


        #coordonare{
          flex-direction: row;
        }

        #imagMobil{
          display: none;
        }

        #imagDesktop{
          display: block;
          height: 100%;
          max-width: 50%;
          object-fit: cover;
        }

        #coordonare{
          padding-right: 0;
          padding-top: 0;
        }

        #secthContainer{
          padding: 0 min(10%, 15rem);
        }
        
        .carousel-container{
          display: flex;
          flex-direction: row;
          align-items: center;
          box-sizing: border-box;
          padding: 0 5vw;
          gap: 3rem;
          overflow-x: hidden;
        }

        #revimg{
          height: 20rem;
          width: auto;
        }

        .butoane{
          width: 15%;
        }

        #buton_dreapta, #buton_stanga{
          display: none;
        }

        #buton_dreapta_desktop, #buton_stanga_desktop{
          height: 100%;
          width: 2.5rem;
          display: block;
        }

        #reviews{
          padding-top: 12rem;
          padding-bottom: 12rem;
        }

        #reviews .titlu{
          text-align: center;
        }

        #mobile_event, #evenimente .butoane{
          display:none;
        }

        #evenimente .titlu{
            text-align: center;
        }

        .events{
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 3rem;
          
        }

        .contact{
          padding: 5rem 15rem;
        }

        .margine{
          display: flex;
          width: 100%;
          justify-content: flex-start;
        }

        .contact_form{
          width: 100%;
        }

        .contact_form .buton_maro{
          width: 18rem;
          height: 3.5rem;
        }

        .inpDesktop{
          display: flex;
          flex-direction: column;
          width: fit-content;
          
        }

        
        .net{
          display: flex;
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        /*  */
        /*  */

        #p1{
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
            display: flex;
            align-items: center;
        }
        
        #pp1{
          grid-column-start: 2;
          grid-column-end: 4;
        }

        #p2{
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 4;
        }

        #pp2{
          grid-row-start: 3;
          grid-row-end: 4;
          grid-column-start: 3;
          grid-column-end: 5;
        }

        #p3{
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 5;
          grid-row-end: 6;
        }

        #pp3{
          grid-row-start: 5;
          grid-row-end: 6;
          grid-column-start: 4;
          grid-column-end: 6;
        }

        #p4{
          grid-row-start: 4;
          grid-row-end: 5;
          grid-column-start: 6;
          grid-column-end: 7;
        }

        #pp4{
          grid-row-start: 4;
          grid-row-end: 5;
          grid-column-start: 7;
          grid-column-end: 9;
        }

        #p5{
          grid-row-start:2;
          grid-row-end: 3;
          grid-column-start: 7;
          grid-column-end: 8;
        }

        #pp5{
          grid-row-start:2;
          grid-row-end: 3;
          grid-column-start: 8;
          grid-column-end: 10;
        }

        .sageata{
          margin: auto;
          display: flex;
          align-items: center;
        }

        #s1{
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
        }

        #s2{
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 4;
          grid-row-end:5;
        }

        #s3{
          grid-column-start: 6;
          grid-column-end: 7;
          grid-row-start: 5;
          grid-row-end: 6;
          transform: rotateZ(-70deg);
        }

        #s4{
          grid-row-start: 3;
          grid-row-end: 4;
          grid-column-start: 7;
          grid-column-end: 8;
          transform: rotateZ(-70deg);
        }

        /*  */
        /*  */
      }
</style>

