import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
//import About from './components/About.vue';
import ContactPage from '@/views/ContactPage';
import GDPR from '@/views/GDPR';
import TNC from '@/views/TNC';
import EventPage from '@/views/EventPage';
import EvenimentePagina from '@/views/EvenimentePagina';

const routes = [
  { path: '/', component: HomePage, name: "Home" },
  {path: '/contact', component: ContactPage, name: "Contact"},
  {path: '/gdpr', component:GDPR, name:"GDPR"},
  {path: '/tnc', component:TNC, name:"TNC"},
  {
    path: '/event/:id',
    name: 'Event',
    component: EventPage,
    props: true // Ensure that this is set if you're passing params as props
  },
  {path: '/events', component:EvenimentePagina, name:"Evenimente"}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;