<template>
    <AppHeader />
        <div class="flex col" id="gdpr">
            <h1 class="titlu">Termeni și Conditii</h1>
            <p>
              <span class="bold">Date generale</span><br><br> Site-ul web enamora.ro aparține și este administrat de către Meira Dragusin Design SRL, societate ce poate fi contactată : telefonic: +40754 899 945 email: contact@enamora.ro Accesarea site-ului www.enamora.ro presupune acceptarea în întregime a Termenilor și condițiilor utilizării website-ului. Ne rezervăm dreptul de a modifica în orice moment Termenii și condițiile, fără o notificare prealabilă. Orice modificare va fi publicată pe site-ul enamora.ro, urmând a intra în vigoare la data publicării.
              <br><br><br><br> <span class="bold"> Proprietate intelectuala</span> <br><br> Conținutul site-ului enamora.ro este proprietatea Meira Dragusin Design SRL. Orice folosire, parțială sau integrală, a conținutului acestuia constituie o încălcare a Legii protecției drepturilor de autor nr. 8/1996, privind dreptul de autor și drepturile conexe, cu modificările și completările ulterioare. Utilizatorii site-ului pot folosi conținutul acestuia doar pentru uz personal sau în scopuri de informare a altor cititori, caz în care au obligația de a menționa sursa. Folosirea conținutului site-ului se va face doar cu acordul prealabil, obținut în scris, la adresa contact@enamora.ro .            </p>
        </div>

        
    <AppFooter />
    <AppCookie />
  </template>
  
  <script>
  export default {
    name: 'TNC',
    // Component logic goes here
  }
  </script>
  
  <style scoped>

      body{
        background-color: #121212;
      }

      #gdpr{
        background-color: #121212;
        min-height: 100vh;
        padding-top: 20vh;
        box-sizing: border-box;
        color: white;
        padding: 10vw;
      }

      #gdpr .titlu{
        align-self: center;
        width: fit-content;
        font-size: 6rem;
        margin-top: 10vh;
      }

      .bold{
        
        font-size: 1.5rem;
      }
  </style>