<template>
  <AppHeader />
  
    <div class="container col" id="rec">
        <h1>Plan the wedding of your <span>dreams</span></h1>
        <a href="" class="buton_maro scroll" @click="scrollToContact">Planifică evenimentul</a>
    </div>
    <div class="container col" id="misiune">
      <div class="half_text">
          <h1 class="titlu">Misiunea noastră</h1>
          <p class="paragraf">Ai ajuns să te gândești la ziua cea mare, dar poate te simți copleșit
            de toate detaliile implicate? Eu sunt aici pentru tine,
              încă de la început până la final, pentru a planifica detaliat
              fiecare aspect al evenimentului tău special.<br><br> De la concepție,
                până la selecția furnizorilor și organizarea întâlnirilor necesare,
                precum și gestionarea programului și coordonarea zilei evenimentului
                  - toate acestea sunt în mâinile mele, astfel încât tu să poți
                  sta liniștit pe tot parcursul anului.</p>
          <a href="" class="buton_maro scroll" @click="scrollToContact">Planifică evenimentul</a>
        </div>
        <img src="../assets/Group 201.png" alt="poza">
    </div>
    <div class="container col" id="pasi">
        <h1 class="titlu">Te ajutăm în 3 pași simpli</h1>
        <div id="pasiDesktop">
          <div class="pas">
            <img src="../assets/p1.svg" alt="pas1">
            <p>Planificarea completă a nunții</p>
          </div>
          <div class="pas">
            <img src="../assets/p2.svg" alt="pas1">
            <p>Coordonarea zilei nunții</p>
          </div>
          <div class="pas">
            <img src="../assets/p3.svg" alt="pas1">
            <p>Consultanță individuală</p>
          </div>
        </div>
    </div>
    <div class="container col" id="planificare">
        <h1 class="titlu">Planificare completă</h1>
        <!-- <div class="gridContainer">
          
            <div class="planMobil">
              <p class="plans" id="p1">1</p>
              <p id="pp1" class="pp">Elaborarea conceptului și selecția tematicii.</p>
            </div>

            <p class="plans" id="p1">1</p>
            <p id="pp1" class="pp">Elaborarea conceptului și selecția tematicii.</p>

            <div class="sageata" id="s1"><img src="../assets/sageata.png" alt=""></div>

            <p class="plans" id="p2">2</p>
            <p id="pp2" class="pp">Recomandări pentru selectarea furnizorilor în funcție de concept și inițierea contactului pentru oferte.</p>

            <div class="planMobil">
              <p class="plans" id="p2">2</p>
              <p id="pp2" class="pp">Recomandări pentru selectarea furnizorilor în funcție de concept și inițierea contactului pentru oferte.</p>
            </div>

            <div class="sageata" id="s2"><img src="../assets/sageata.png" alt=""></div>
            
            <div class="planMobil">
              <p class="plans" id="p3">3</p>
              <p id="pp3" class="pp">Stabilirea unui program precis până la ziua evenimentului, cu monitorizarea constantă a stadiului fiecărui furnizor.</p>
            </div>

            <p class="plans" id="p3">3</p>
            <p id="pp3" class="pp">Stabilirea unui program precis până la ziua evenimentului, cu monitorizarea constantă a stadiului fiecărui furnizor.</p>

            <div class="sageata" id="s3"><img src="../assets/sageata.png" alt=""></div>

            <p class="plans" id="p4">4</p>
            <p id="pp4" class="pp">Coordonarea și gestionarea tuturor furnizorilor pe parcursul întregului an, precum și în ziua evenimentului.</p>

            <div class="planMobil">
              <p class="plans" id="p4">4</p>
              <p id="pp4" class="pp">Coordonarea și gestionarea tuturor furnizorilor pe parcursul întregului an, precum și în ziua evenimentului.</p>
            </div>

            <div class="sageata" id="s4"><img src="../assets/sageata.png" alt=""></div>

            <p class="plans" id="p5">5</p>
            <p id="pp5" class="pp">Crearea unui program și asigurarea că ziua decurge conform planului, inclusiv asigurarea necesarului pentru furnizorii finali.</p>

            <div class="planMobil">
              <p class="plans" id="p5">5</p>
              <p id="pp5" class="pp">Crearea unui program și asigurarea că ziua decurge conform planului, inclusiv asigurarea necesarului pentru furnizorii finali.</p>
            </div>
          </div> -->
          <div class="timeline-container">
            <div class="timeline-item left">
                <div class="timeline-content">
                    <p>01. Elaborarea conceptului și selecția tematicii.</p>
                </div>
                <div class="timeline-point"></div>
            </div>
            <div class="timeline-item right">
                <div class="timeline-point"></div>
                <div class="timeline-content">
                    <p>02. Recomandări pentru selectarea furnizorilor în funcție de concept și inițierea contactului pentru oferte.</p>
                </div>
            </div>
            <div class="timeline-item left">
                <div class="timeline-content">
                    <p>03. Crearea unui program și asigurarea că ziua decurge conform planului, inclusiv asigurarea necesarului pentru furnizorii finali.</p>
                </div>
                <div class="timeline-point"></div>
            </div>
            <div class="timeline-item right">
                <div class="timeline-point"></div>
                <div class="timeline-content">
                    <p>04. Planificarea și gestionarea detaliată a fiecărei etape a procesului.</p>
                </div>
            </div>
            <div class="timeline-item left">
                <div class="timeline-content">
                    <p>05. Stabilirea unui program precis până la ziua evenimentului, cu monitorizarea constantă a stadiului fiecărui furnizor.</p>
                </div>
                <div class="timeline-point"></div>
            </div>
            <div class="timeline-item right">
                <div class="timeline-point"></div>
                <div class="timeline-content">
                    <p>06. Coordonarea și gestionarea tuturor furnizorilor pe parcursul întregului an, precum și în ziua evenimentului.</p>
                </div>
            </div>
        </div>
      </div>
    <div class="container col" id="coordonare">
        <div class="container col" id="secthContainer">
            <h1 class="titlu">Coordonarea evenimentului & consultanta</h1>
            <p class="paragraf">Ai început să-ți pui lucrurile în ordine, dar te-ar ajuta sfatul unui expert?
              Vom planifica împreună următoarele etape, astfel încât fiecare detaliu să se potrivească perfect!<br><br>
                Dorești să te ocupi personal de toată planificarea, dar nu vrei să ai nicio grijă în ziua cea mare?
                Cu siguranță! <br><br>Preiau întregul proces și îngrijorările tale, asigurându-mă că totul se desfășoară conform planului!</p>
          </div>
        <img src="../assets/pop.png" alt="" id="imagMobil">
        <img src="../assets/popDesktop.png" alt="" id="imagDesktop">
    </div>
    <div class="container col" id="reviews">
        <h1 class="titlu">Ce spun <br class="mobilOnly">clientii nostri</h1>
        
        <div class="carousel-container">
          <img src="../assets/Group 195.svg" alt="But_stanga" id="buton_stanga_desktop" @click="prevSlide">
          <img :src="reviews[currentIndex].image" alt="Review Image" id="revimg" class="animate__animated" :class="currentAnimation">
          <p class="paragraf animate__animated" :class="currentAnimation" v-html="reviews[currentIndex].text + reviews[currentIndex].author">
          </p>
          <img src="../assets/Group 196.svg" alt="But_dreapta" id="buton_dreapta_desktop" @click="nextSlide">
        </div>
        <div class="butoane">
          <img src="../assets/Group 195.svg" alt="But_stanga" id="buton_stanga" @click="prevSlide">
          <div class="punct" :class="{ 'auriu': currentIndex === 0 }" @click="setActiveDot(0)"></div>
          <div class="punct" :class="{ 'auriu': currentIndex === 1 }" @click="setActiveDot(1)"></div>
          <div class="punct" :class="{ 'auriu': currentIndex === 2 }" @click="setActiveDot(2)"></div>
          <div class="punct" :class="{ 'auriu': currentIndex === 3 }" @click="setActiveDot(3)"></div>
          <img src="../assets/Group 196.svg" alt="But_dreapta" id="buton_dreapta" @click="nextSlide">
        </div>
    </div>
    <div class="container col" id="evenimente">
      <h1 class="titlu">Cum arată evenimentele realizate de noi</h1>
      <div class="event" id="mobile_event">
          <a :href="events[currentEventIndex].link">
            <img :src="events[currentEventIndex].image" alt="Event_image" class="animate__animated" :class="currentEventAnimation">
          </a>
          <p class="animate__animated" :class="currentEventAnimation">{{events[currentEventIndex].description}}</p>
      </div>
      <div class="butoane">
        <img src="../assets/Group 195.svg" alt="But_stanga" id="buton_stanga" @click="prevEvent">
        <div class="punct" :class="{'auriu':currentEventIndex === 0}" @click="setActiveDot(0)"></div>
        <div class="punct" :class="{'auriu':currentEventIndex === 1}" @click="setActiveDot(1)"></div>
        <div class="punct" :class="{'auriu':currentEventIndex === 2}" @click="setActiveDot(2)"></div>
        <div class="punct" :class="{'auriu':currentEventIndex === 3}" @click="setActiveDot(2)"></div>
        <img src="../assets/Group 196.svg" alt="But_dreapta" id="buton_dreapta" @click="nextEvent">
      </div>


      <div class="events">
        <div 
          class="event" 
          v-for="event in events" 
          :key="event.id" 
        >
          <a :href="event.link">
            <img :src="event.image" alt="Event image" class="animate__animated" :class="currentEventAnimation">
          </a>
          <p class="animate__animated" :class="currentEventAnimation">{{ event.description }}</p>
        </div>
      </div>



    </div>
    <div class="container col contact" id="contactPart">
      <div class="margine">
        <h1 class="titlu">Hai sa planificam evenimentul</h1>
      </div>
      <form action="" class="contact_form">
        <div class="net">
          <div class="inpDesktop">
            <label for="nume">Numele tău</label>
            <input type="text" name="nume" id="nume" placeholder="Numele tău">
          </div>
          <div class="inpDesktop">
            <label for="email">Adresa de Email</label>
            <input type="text" name="email" id="email" placeholder="Email">
          </div>

          <div class="inpDesktop">
            <label for="telefon">Număr de telefon</label>
            <input type="text" name="telefon" id="telefon" placeholder="Număr de telefon">
          </div>
        </div>
        <label for="info">Mai multe despre tine (opțional)</label>
        <textarea name="info" id="info" cols="30" rows="10" placeholder="Sunt pasionată de flori…"></textarea>
        
        <button name="submit" class="buton_maro">Planifică evenimentul</button>
      </form>
    </div>
    <AppFooter />
    <AppCookie />
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentIndex: 0,
        currentEventIndex: 0,
        currentAnimation: '',
        currentEventAnimation:'',
        reviews: [
          {
            image: require('../assets/poza1.png'),
            text: 'Meira a fost wedding planner-ul nostru in Septembrie 2023. Felul in care a gestionat si coordonat discutiile cu furnizori m-a facut sa fiu relaxata la marele eveniment si sa ma pot bucura de el cu adevarat. <br><br>Este foarte atenta la detalii si are un simt estetic extraordinar. <br><br>Iti multumim inca o data pentru tot, Meira!❤️',
            author: '<br><br>-Diana, mireasă'
          },
          {
            image: require('../assets/poza2.png'),
            text: 'Meira a fost Event Planner pentru botezul bebelusului nostru. A fost un eveniment de 300 de invitati și cu multi furnizori. <br><br>Nu am cuvinte sa descriu cat de frumos a iesit evenimentul și cu cata atentie la detalii a organizat totul. 🤍',
            author: '<br><br>-Oana Dănilă'
          },
          {
            image: require('../assets/poza3.png'),
            text: 'O fata minunata care se implica cu tot sufletul si face ca evenimentul fiecaruia sa fie mai mult decat si-a imaginat. <br><br>Cu multa pasiune si energie e mereu prezenta si deschisa la orice idee. O recomand cu drag🤍',
            author: '<br><br>--Francisca Petrescu'
          },
          {
            image: require('../assets/poza4.png'),
            text: 'Mi-a fost de mare folos in ziua nuntii, m-a ajutat cu tot ce am avut nevoie si ce a mai aparut pe parcurs.<br><br> Daca nu aveti wedding planner, recomand in ziua nuntii sa aveti un coordonator, deoarece apar lucruri la care nu te astepti.',
            author: '<br><br>---Elena'
          },
          // Add the other two review objects here
        ],
        events: [
          {
            image: require('@/assets/poza1_2.png'), // Adjust the path as necessary
            description: 'Diana si Sebastian',
            link: "/event/1"
          },
          {
            image: require('@/assets/poza2_2.png'), // Adjust the path as necessary
            description: 'Baby Carol',
            link: "/event/2"
          },
          {
            image: require('@/assets/poza3_2.png'), // Adjust the path as necessary
            description: 'Baby Natalia',
            link: "/event/3"
          },
          {
            image: require('@/assets/poza4.png'), // Adjust the path as necessary
            description: 'Elena & Alex',
            link: "/event/4"
          },
        ]
      };
    },
    methods: {
      nextSlide() {
        this.currentAnimation = 'animate__fadeOutLeft';
        setTimeout(() => {
          this.currentIndex = (this.currentIndex + 1) % this.reviews.length;
          this.currentAnimation = 'animate__fadeInRight';
        }, 550); // Timeout should roughly match your animation duration
      },
      prevSlide() {
        this.currentAnimation = 'animate__fadeOutRight';
        setTimeout(() => {
          this.currentIndex = (this.currentIndex + this.reviews.length - 1) % this.reviews.length;
          this.currentAnimation = 'animate__fadeInLeft';
        }, 550); // Adjust timeout as needed
      },
      setActiveDot(index) {
        this.currentIndex = index;
      },
      
      nextEvent() {
        this.currentEventAnimation = 'animate__fadeOutLeft';
        setTimeout(() => {
          this.currentEventIndex = (this.currentEventIndex  + 1) % this.events.length;
          this.currentEventAnimation = 'animate__fadeInRight';
        }, 550);
      },
      prevEvent() {
        this.currentEventAnimation = 'animate__fadeOutRight';
        setTimeout(() => {
          this.currentEventIndex = (this.currentEventIndex + this.events.length - 1) % this.events.length;
          this.currentEventAnimation = 'animate__fadeInLeft';
        }, 550);
      },
      setActiveDotEvent(index) {
        this.currentEventIndex = index;
        this.currentEventAnimation = 'animate__fadeInRight';
      },
      scrollToContact(event) {
        event.preventDefault();

        const contactPart = document.getElementById('contactPart');
        if (!contactPart) return;

        const targetPosition = contactPart.getBoundingClientRect().top + window.pageYOffset;
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        const duration = 1500; // Duration in milliseconds, adjust for slower or faster scrolling
        let start = null;

        function step(timestamp) {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const currentScrollPosition = startPosition + distance * easeInOutCubic(progress / duration);

          window.scrollTo(0, currentScrollPosition);

          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        }

        function easeInOutCubic(t) {
          return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
        }

        window.requestAnimationFrame(step);
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>