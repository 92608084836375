<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <transition name="fade">
        <div v-if="showCookieConsent" class="cok">
            <div class="notice">
                <div id="textCok">
                    <div class="flex">
                        <p id="titluCok">Cookies?</p>
                        <div id="X"></div>
                    </div>
                    <p id="parCok">Apasand pe “Accept”, esti de acord cu stocarea cookie-urilor pe device-ul 
                        tau pentru a imbunatati experienta pe site, a analiza utilizarea 
                        site-ului si a ne ajuta cu eforturile noastre de marketing. Info</p>
                    <p></p>
                </div>
                <div id="butoaneCok">
                    <button id="accept" @click="acceptCookies">Accept all<img src="../assets/cookie.svg" alt="cookie"></button>
                    <!-- <button id="refuse" @click="refuseCookies">Refuse all</button> -->
                </div>
            </div>
        </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'AppCookie',
    data() {
        return {
        showCookieConsent: true, // Change this to a method that checks for the cookie if needed
        };
    },
    methods: {
        acceptCookies() {
        // Set a cookie to record the user's consent
        const expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)); // 1 year from now
        document.cookie = `cookieConsent=accepted; expires=${expiryDate.toUTCString()}; path=/`;
        
        // Hide the cookie consent notice
        this.showCookieConsent = false;
        },
        checkCookieConsent() {
        // Check if the cookieConsent cookie exists
        return document.cookie.split(';').some((item) => item.trim().startsWith('cookieConsent='));
        }
    },
    mounted() {
        // Check if the consent has already been given
        this.showCookieConsent = !this.checkCookieConsent();
    }
    }
  </script>
  
  <style scoped>
    .cok{
        width: 100%;
        display: flex;
        height: 25rem;
        position: fixed;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
        justify-content: center;
        z-index: 20000;
    }

    .notice{
        width: 80%;
        height: 15rem;
        max-width: 25rem;
        align-self: center;
        background-color: #121212;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
    }

    #textCok{
        width: 100%;
        height: 75%;
    }

    #butoaneCok{
        width: 100%;
        height: 25%;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        background-color: #E2E1DF;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 2rem;
    }

    #accept{
        background-color: #A58A5E;
        border: 2px solid #8163349A;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 35%;
        height: 65%;
        border-radius: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: white;
        font-weight: 600;
        cursor: pointer;
    }

    #refuse{
        background-color: #A58A5E;
        border: 2px solid #8163349A;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 35%;
        height: 65%;
        border-radius: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: white;
        font-weight: 600;
        cursor: pointer;
    }

    #textCok{
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        box-sizing: border-box;

    }

    #titluCok{
        font-size: 1.6rem;
        margin: 0;
        color: white;
        font-weight: 700;
    }

    #parCok{
        color: white;
        font-size: 0.8rem;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    @media (min-width: 1024px) {
        .cok{
            justify-content: flex-start;
            padding-left: 3rem;
            width: 50rem;
        }
    }
  </style>