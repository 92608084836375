<template>
    <div>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactPage',
    // Component logic goes here
  }
  </script>
  
  <style scoped>
  /* CSS for Home.vue */
  </style>