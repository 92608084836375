<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <footer class="container col">
        <img src="../assets/Enamora.svg" alt="" id="enamora2">
        <div class="links">
            <img src="../assets/Enamora.svg" alt="" id="enamora">
            <a href="#misiune">Misiune</a>
            <a href="#pasi">Servicii</a>
            <a href="#planificare">Planificarea completă</a>
            <a href="#coordonare">Coordonarea zilei & consultanta</a>
            <a href="#contactPart">Contact</a>
            <a href="/tnc">Termeni și Conditii</a>
            <a href="/gdpr">Gdpr</a>
        </div>
        <span class="linie"></span>
        <div id="copyright">
            <div class="flex">
                <p>@ 2023 Enamora Events.</p>
                <p>All rights reserved</p>
            </div>
            <div class="socialMediaDesktop">
                <a href=""><img src="../assets/linkedin.svg" alt="LinkedIn"></a>
                <a href=""><img src="../assets/instagram.svg" alt="Instagram"></a>
            </div>
        </div>
        <div class="socialMedia">
            <a href=""><img src="../assets/linkedin.svg" alt="LinkedIn"></a>
            <a href=""><img src="../assets/instagram.svg" alt="Instagram"></a>
        </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooter',
    // Component logic goes here
  }
  </script>
  
  <style scoped>
        footer{
            height: fit-content;
            background-color: #121212;
            padding-left: 3rem;
            padding-right: 3rem;
            padding-top: 5rem;
            padding-bottom: 3rem;
            box-sizing: border-box;
            margin-top: auto;
        }

        #enamora{
            width: 70%;
        }

        #enamora2{
            display: none;
        }

        footer a{
            text-decoration: none;
            color: white;
            margin-top: 1.8rem;
        }

        .socialMediaDesktop{
            display: none;
        }

        .linie{
            background-color: white;
            width: 100%;
            height: 0.1rem;
            margin-top: 1.8rem;
        }

        #copyright{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            color: white;
        }

        #copyright p{
            height: 0.5rem;
        }

        #copyright .flex{
            flex-direction: column;
            text-align: center;
        }

        .socialMedia{
            display: flex;
            align-self: center;
            justify-content: space-around;
            width: 50%;
        }

        .socialMedia a img{
            height: 2rem;
        }

        .links{
            display: flex;
            flex-direction: column;
            align-items: flex;
        }

        @media (min-width: 1024px) {
            #enamora{
                display: none;
            }

            #enamora2{
                display: block;
            }
            footer{
                align-items: flex-start;
                box-sizing: border-box;
                padding: 3rem 10rem;
            }

            .links{
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                margin-top: 2rem;
            }
            #copyright{
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
            }

            #copyright .flex{
                flex-direction: row;
                gap: 0.5rem;
            }

            .socialMedia{
                display: none;
            }

            .socialMediaDesktop{
                display: flex;
                gap: 2.5rem;
            }

            .socialMediaDesktop a img{
                width: 2rem;
            }
        }
  </style>