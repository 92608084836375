import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './styles.css';
import AppHeader from './components/header.vue';
import AppFooter from './components/footer.vue';
import AppCookie from './components/cookie.vue';

import 'animate.css';

createApp(App)
.component('AppHeader', AppHeader)
.component('AppFooter', AppFooter)
.component('AppCookie', AppCookie)
.use(router)
.mount('#app');

