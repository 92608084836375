<!-- eslint-disable vue/multi-word-component-names -->

<template>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
    <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
    <header>
        <div id="black_bar" :class="{'white-border': isMenuOpen}">
            <div id="email_phone">
                <p id="telefon"><span class="material-symbols-outlined">phone_in_talk</span>+40754 899 945</p>
                <p id="email"><span class="material-symbols-outlined">mail</span>enamoraevents@gmail.com</p>
            </div>
            <div id="instaContainer">
                <a href="https://www.instagram.com/enamora_events/" id="instagram"><img src="../assets/instagram.svg" alt="@enamora_events"><span id="instagram_tag"><p>enamora_events</p></span></a>
            </div>
        </div>
        <div class="mobile-nav-drawer" :class="{ 'drawer-open': isMenuOpen }">
            <!-- Menu content (links, etc.) -->
            <div class="flex col" style="position: absolute; bottom: 0; padding-bottom: 2rem; gap:1rem;">
                <a href="#misiune">Misiune</a>
                <a href="#pasi">Servicii</a>
                <a href="#planificare">Planificarea completă</a>
                <a href="#coordonare">Coordonarea zilei & consultanta</a>
                <a href="/events">Evenimentele noastre</a>
                <a @click="scrollToContact" href="">Contact</a>
            </div>
        </div>
        <nav :class="{ 'butonDreapta' : isMenuOpen }">
            <a href="#misiune" class="pages">Misiune</a>
            <a href="#pasi" class="pages">Servicii</a>
            <a href="#planificare" class="pages">Planificarea completă</a>
            <a :class="{ 'fixed' : isMenuOpen }" href="/"><img src="../assets/Enamora.svg" alt="Enamora | Wedding & Event planning" id="logo"></a>
            <a href="#coordonare" class="pages">Coordonarea evenimentului & consultanta</a>
            <a @click="scrollToContact" href="" class="pages">Contact</a>
            <div id="nav-icon2" @click="toggleMenu">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <!-- <router-link to="/">Home</router-link>
            <router-link to="/contact">Contact</router-link> -->
            
        </nav>
    </header>
  </template>
  
  <script>
    import $ from 'jquery';
    
    export default {
        data() {
            return {
                isMenuOpen: false,
            };
        },
        methods: {
            toggleMenu(event) {
                this.isMenuOpen = !this.isMenuOpen;
                if (event) {
                    event.stopPropagation();
                }
            },
            closeMenu() {
                if (this.isMenuOpen) {
                    this.isMenuOpen = false;
                }
            },
            scrollToContact(event) {
                event.preventDefault();
                this.closeMenu();  // Close the menu when scrolling to contact
    
                const contactPart = document.getElementById('contactPart');
                if (!contactPart) return;
    
                const targetPosition = contactPart.getBoundingClientRect().top + window.pageYOffset;
                const startPosition = window.pageYOffset;
                const distance = targetPosition - startPosition;
                const duration = 1500; // Adjust duration as needed
                let start = null;
    
                function step(timestamp) {
                    if (!start) start = timestamp;
                    const progress = timestamp - start;
                    const currentScrollPosition = startPosition + distance * easeInOutCubic(progress / duration);
                    window.scrollTo(0, currentScrollPosition);
                    if (progress < duration) {
                        window.requestAnimationFrame(step);
                    }
                }
    
                function easeInOutCubic(t) {
                    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
                }
    
                window.requestAnimationFrame(step);
            },
        },
        mounted() {
            document.addEventListener('click', (event) => {
                if (!this.$el.contains(event.target) && this.isMenuOpen) {
                    this.closeMenu();
                }
            });
        },
        beforeUnmount() { // Updated to `beforeUnmount`
            document.removeEventListener('click', this.closeMenu);
        }
    }
    
    $(document).ready(function() {
        $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function() {
            $(this).toggleClass('open');
        });
    });
    </script>
    
  
  <style scoped>

        #black_bar{
            background-color: black;
            display: flex;
            justify-content: space-between;
            color: white;
            align-items: center;
            width: 100vw;
            height: 4rem;
            border-bottom: 2px solid white;
            padding-left: 0.5rem;
            padding-right: 1.2rem;
            box-sizing: border-box;

            position: fixed;
            top: 0;
            left: 0;
            z-index: 10000;
        }

        .pages{
            display: none;
        }
        .white-border{
            border-bottom: 0px solid white !important;
        }

        .fixed{
            position: fixed;
            top: 5.5rem;
            left: 3rem;
        }

        .butonDreapta{
            justify-content: flex-end;
        }
        
        .mobile-nav-drawer {
            position: fixed;
            /* top: max(-50%, -22rem);  */
            top: -24rem;
            left: 0;
            width: 100vw;
            /* height: max(40%, 22rem);  */
            height:24rem;
            background-color: #000; /* Add background or other styling as needed */
            transition: transform 0.3s ease-in-out;
            transform: translateY(0);
            z-index: 999; /* Make sure this is less than the z-index of #black_bar */
            border-bottom: 2px solid white;
            padding: 0 3rem;
        }

        .mobile-nav-drawer a{
            color: white;
            text-decoration: none;
        }

        .drawer-open {
            transform: translateY(100%); /* Adjust translateY value to bring the drawer into view */
        }


        #telefon{
            display: flex;
            align-items: center;
            height: 30%;
            
        } 

        #email_phone{
            display: flex;
            width: 90%;
            gap: 5%;
            font-size: 0.85rem;
            opacity: 0.9;
            justify-content: center;
            flex-direction: column;

        }
        #email{
            display: flex;
            align-items: center;
            height: 30%;
        }
        #instagram{
            /* margin-right: 2rem; */
            display: flex;
            align-items: center;
            text-decoration: none;
        }
        #instagram_tag{
            display: none;
        }

        #instagram img{
            height: 1rem;
        }

        nav{
            background-color: transparent;
            padding-top: 1rem;
            display: flex;
            height: 3rem;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            z-index: 1000;
            padding: 3rem 3rem;
            box-sizing: border-box;
        }

        #logo{
            width: 10rem
        }

        .material-symbols-outlined {
            font-variation-settings:
            'FILL' 0,
            'wght' 100,
            'GRAD' 0,
            'opsz' 24
        }



        #nav-icon2 {
    width: 3rem;
    height: 2rem;
    /* position: fixed; Changed from relative to fixed */
    top: 5.2rem; /* Adjust based on your header's layout */
    right: 3rem; /* Position it on the right side, adjust as needed */
    z-index: 10001; /* Ensure it's above other content */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

        #nav-icon2 span {
        display: block;
        position: absolute;
        height: 0.2rem;
        width: 1.6rem;
        background: white;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        }

        #nav-icon2 span:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
        }

        #nav-icon2 span:nth-child(odd) {
        left:0px;
        border-radius: 9px 0 0 9px;
        }

        #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
        top: 0px;
        }

        #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
        top: 10px;
        }

        #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
        top: 20px;
        }

        #nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        }

        #nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        }

        #nav-icon2.open span:nth-child(1) {
        left: 5px;
        top: 7px;
        }

        #nav-icon2.open span:nth-child(2) {
        left: calc(50% - 5px);
        top: 7px;
        }

        #nav-icon2.open span:nth-child(3) {
        left: -50%;
        opacity: 0;
        }

        #nav-icon2.open span:nth-child(4) {
        left: 100%;
        opacity: 0;
        }

        #nav-icon2.open span:nth-child(5) {
        left: 5px;
        top: 1.3rem;
        }

        #nav-icon2.open span:nth-child(6) {
        left: calc(50% - 5px);
        top: 1.3rem;
        }

        @media (min-width: 1024px) {

            #instaContainer{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30%;
            }

            #instagram_tag{
                display: block;
                
                color: white;
            }

            #instagram_tag p{
                padding: 0 0.5rem;
            }
            
            #email_phone{
                width: 30%;
                gap: 10%;

                flex-direction: row;

            }

            #nav-icon2{
                display: none;
            }

            nav{
                justify-content: center;
                margin-top: 2rem;
                height: 4.2rem;
                box-sizing: border-box;
                /* gap: 2rem; */
                padding:0 min(10%, 15rem);
                box-sizing: border-box;
                gap: 3rem;
            }

            #logo{
                height: 80%;
                width: auto;
            }

            .pages{
                display: flex;
                margin-left: auto;
                margin-right: auto;
                color: white;
                text-decoration: none;
                max-width: 20%;
                text-align: center;
            }
        }
  </style>